<i18n lang="yaml">
en:
  terms: Terms
  privacy: Privacy
  partners: Partners
  copyright: © {year} RVezy. All rights reserved.

fr:
  terms: Termes
  privacy: Confidentialité
  partners: Partenaires
  copyright: © {year} RVezy. Tous droits réservés.
</i18n>

<template>
  <footer class="flex-grow-0 flex-shrink-0">
    <Container class="mb-10">
      <div class="copyright">
        <div>{{ t('copyright', { year: new Date().getUTCFullYear() }) }}</div>
        <div class="legal">
          <NuxtLink :to="getLocalePath('/c/terms-and-conditions')">{{ t('terms') }}</NuxtLink>
          <span>|</span>
          <NuxtLink :to="getLocalePath('/c/privacy-policy')">{{ t('privacy') }}</NuxtLink>
          <span>|</span>
          <NuxtLink :to="getLocalePath('/c/partners')">{{ t('partners') }}</NuxtLink>
        </div>
      </div>
    </Container>
  </footer>
</template>

<script lang="ts" setup>
const { t } = useI18n({ useScope: 'local' })
</script>

<style lang="scss" scoped>
footer {
  background-color: $primary-500;
  color: #fff;
  padding: 1rem 0;

  .copyright {
    font-size: 0.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    grid-gap: 3rem;

    @media (min-width: 1024px) {
      flex-direction: row;
    }

    .legal {
      display: flex;
      grid-gap: 0.5rem;
    }

    a {
      color: #fff;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
